<div class="wrapper">
  <div class="container">
    <div class="row">
      <div class="col-md-12">

        <div class="card-box">

          <ul class="nav nav-tabs">
            <li class="nav-item">
              <a href="#profile" data-toggle="tab" aria-expanded="false" class="nav-link active">
                My Profile
              </a>
            </li>
            <li class="nav-item">
              <a href="#roles" data-toggle="tab" aria-expanded="true" class="nav-link ">
                My Roles
              </a>
            </li>
            <li class="nav-item">
              <a href="#sms" data-toggle="tab" aria-expanded="true" class="nav-link ">
                SMS/Email Notifications
              </a>
            </li>
          </ul>

          <div class="tab-content">
            <div class="tab-pane show active" id="profile">

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="control-label">Name</label>
                    <input type="text" class="form-control" [(ngModel)]="user.name" name="user.name" placeholder="John Doe">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="control-label">Email</label>
                    <input type="email" disabled=true class="form-control" [(ngModel)]="user.email" name="user.email" placeholder="example@phoenixhealthgh.com">
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="control-label">Phone Number</label>
                    <input type="text" class="form-control" [(ngModel)]="user.phonenumber" name="user.phonenumber">
                  </div>
                </div>

              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="field-3" class="control-label">Status</label>
                    <select class="form-control" disabled=true [(ngModel)]="user.status" name="user.status" id="example-select">
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="field-3" class="control-label">Profile</label>
                    <select class="form-control" disabled=true [(ngModel)]="user.profile" name="user.profile" id="example-select">
                      <option value="Admin">Admin</option>
                      <option value="Vetting">Vetting Personnel</option>
                      <option value="Staff">Staff</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-12">
                  <button type="button" (click)=updateProfileInfo() *ngIf="!saving" class="btn btn-info waves-effect waves-light">Update</button>
                  <button type="button" *ngIf="saving" disabled=true class="btn btn-info waves-effect waves-light"><span class="loading-text"><i class="bi bi-arrow-repeat"></i> Saving...</span></button>
                </div>

              </div>

            </div>
            <div class="tab-pane" id="roles">

              <div class="row">
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table table-hover mb-0">
                      <thead>
                        <tr>
                          <th>Role</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let role of user.roles">
                          <td>{{role.rolename}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane" id="sms">

              <div class="row">

                <div class="col-md-6">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" [(ngModel)]="user.smspreference" name="user.smspreference">
                    <label class="form-check-label">Receive SMS Notifications</label>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" [(ngModel)]="user.emailpreference" name="user.emailpreference">
                    <label class="form-check-label">Receive Email Notifications</label>
                  </div>
                </div>

                <div class="col-md-12">
                  <br>
                  <button type="button" (click)=updateNotificationPref() *ngIf="!saving" class="btn btn-info waves-effect waves-light">Update</button>
                  <button type="button" *ngIf="saving" disabled=true class="btn btn-info waves-effect waves-light"><span class="loading-text"><i class="bi bi-arrow-repeat"></i> Saving...</span></button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
