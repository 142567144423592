<div class="content-page">
  <div class="content">

    <div class="navbar-custom">
      <a class="logo text-center d-none d-md-inline-block mr-2">
        <span class="logo-lg">
          <h4 class="text-white">Phoenix Health API Doc</h4>
        </span>
      </a>

      <button type="button" class="btn btn-sm btn-dark d-inline-block d-lg-none button-menu-mobile">Menu</button>

      <span class="badge badge-danger float-right">v3.4.0</span>
    </div>

    <div class="container-fluid">

      <div class="page-wrapper">

        <div id="topnav" class="mt-70">
          <div class="">
            <div class="container-fluid">
              <div id="navigation">
                <ul class="navigation-menu">

                  <li class="has-submenu">
                    <a routerLink="/app/admin/profile">
                      <i class="bi bi-display"></i>API Documentation</a>
                  </li>

                </ul>

                <div class="clearfix"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="page-content" style="padding-top: 150px;">
          <div class="card m-b-30">
            <div class="card-body">
              <div class="form-group col-md-4">
                <label>Select API Version</label>
                <select class="form-control" name="currentVersion" [(ngModel)]="currentVersion">
                  <option value="1.0">v1.0 (Latest)</option>
                  <!-- <option value="2.0.0">v2.0.0</option> -->
                </select>
              </div>
            </div>
          </div>

          <div class="card m-b-30" *ngIf="currentVersion === '1.0'">
            <div class="card-body">

              <div class="p-lg-3">
                <h4 class="mt-0 mb-2 font-weight-bold">Search Member by Member ID &#123; &#125;</h4>
                <h6>Endpoint: <code>/v1.0/member/verify</code></h6>
                <h6>HTTP Method: <code>POST</code></h6>
                <h6>HTTP Header: <code>Authentication-Type</code></h6>
                <h6>Request Body: <code> &#123;memberid[string]&#125;</code></h6>
                <h6>Response Body: <code>&#123;status[string], message[string], client&#125;</code></h6>
                <code>&#123;"status": "200", "message": "Transaction Successful"&#125;</code> <br>

                <h6>Status Codes: </h6>
                <code>&#123;200: "Transaction Successful"&#125;</code> <br>
                <code>&#123;201: "Member Not Found"&#125;</code> <br>
                <code>&#123;500: "Internal Server Error"&#125;</code> <br>
                <code>&#123;400: "Bad Request, Check Request API Key"&#125;</code> <br>
                <code>&#123;405: "Method not allowed"&#125;</code>

                <h4>Examples </h4>
                <div class="accordion" id="accordionExample">

                  <div class="card">
                    <div class="card-header" id="headingOne">
                      <h2 class="mb-0">
                        <button class="btn btn-link btn-block text-left" style="padding: 0;" type="button"
                          data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                          aria-controls="collapseOne">
                          cURL
                        </button>
                      </h2>
                    </div>

                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                      data-parent="#accordionExample">
                      <div class="card-body" style="color: #17ff0b; background-color: #444;">
                        <pre style="color: #17ff0b; background-color: #444;"><code>
                          curl --location --request POST '/v1.0/member/verify' \
                          --header 'Authorization-Type: 8acc1bc8-1dc3-4085-a5e8-dcf026ea0c4e' \
                          --header 'Content-Type: application/json' \
                          --data-raw '&#123;"memberid": "2010040001"&#125;'
                        </code></pre>
                      </div>
                    </div>
                  </div>

                  <div class="card">
                    <div class="card-header" id="headingTwo">
                      <h2 class="mb-0">
                        <button class="btn btn-link btn-block text-left collapsed" style="padding: 0;" type="button"
                          data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
                          aria-controls="collapseTwo">
                          PHP - cURL
                        </button>
                      </h2>
                    </div>
                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                      <div class="card-body" style="color: #17ff0b; background-color: #444;">
                        <pre style="color: #17ff0b; background-color: #444;"><code>
                          <?php

                          $curl = curl_init();

                          curl_setopt_array($curl, array(
                          CURLOPT_URL => "/v1.0/member/verify",
                          CURLOPT_RETURNTRANSFER => true,
                          CURLOPT_ENCODING => "",
                          CURLOPT_MAXREDIRS => 10,
                          CURLOPT_TIMEOUT => 0,
                          CURLOPT_FOLLOWLOCATION => true,
                          CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
                          CURLOPT_CUSTOMREQUEST => "POST",
                          CURLOPT_POSTFIELDS =>"&#123;\"memberid\": \"2010040001\"&#125;",
                          CURLOPT_HTTPHEADER => array(
                          "Authorization-Type: 8acc1bc8-1dc3-4085-a5e8-dcf026ea0c4e",
                          "Content-Type: application/json"
                          ),
                          ));

                          $response = curl_exec($curl);

                          curl_close($curl);
                          echo $response;
                        </code></pre>
                      </div>
                    </div>
                  </div>

                  <div class="card">
                    <div class="card-header" id="headingThree">
                      <h2 class="mb-0">
                        <button style="padding: 0;" class="btn btn-link btn-block text-left collapsed" type="button"
                          data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                          aria-controls="collapseThree">
                          PHP - HTTP_Request2
                        </button>
                      </h2>
                    </div>
                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                      data-parent="#accordionExample">
                      <div class="card-body" style="color: #17ff0b; background-color: #444;">
                        <pre style="color: #17ff0b; background-color: #444;">
                          <code>
                            <?php
                            require_once 'HTTP/Request2.php';
                            $request = new HTTP_Request2();
                            $request->setUrl('/v1.0/member/verify');
                            $request->setMethod(HTTP_Request2::METHOD_POST);
                            $request->setConfig(array(
                            'follow_redirects' => TRUE
                            ));
                            $request->setHeader(array(
                            'Authorization-Type' => '8acc1bc8-1dc3-4085-a5e8-dcf026ea0c4e',
                            'Content-Type' => 'application/json'
                            ));
                            $request->setBody('&#123;"memberid": "2010040001"&#125;');
                            try &#123;
                            $response = $request->send();
                            if ($response->getStatus() === 200) &#123;
                            echo $response->getBody();
                            &#125;
                            else &#123;
                            echo 'Unexpected HTTP status: ' . $response->getStatus() . ' ' .
                            $response->getReasonPhrase();
                            &#125;
                            &#125;
                            catch(HTTP_Request2_Exception $e) &#123;
                            echo 'Error: ' . $e->getMessage();
                            &#125;
                          </code>
                        </pre>
                      </div>
                    </div>
                  </div>

                  <div class="card">
                    <div class="card-header" id="headingFour">
                      <h2 class="mb-0">
                        <button style="padding: 0;" class="btn btn-link btn-block text-left collapsed" type="button"
                          data-toggle="collapse" data-target="#collapseFour" aria-expanded="false"
                          aria-controls="collapseFour">
                          Nodejs - Request
                        </button>
                      </h2>
                    </div>
                    <div id="collapseFour" class="collapse" aria-labelledby="headingFour"
                      data-parent="#accordionExample">
                      <div class="card-body" style="color: #17ff0b; background-color: #444;">
                        <pre style="color: #17ff0b; background-color: #444;">
                          <code>
                            var request = require('request');

                            var options = &#123;
                            'method': 'POST',
                            'url': '/v1.0/member/verify',
                            'headers': &#123;
                            'Authorization-Type': '8acc1bc8-1dc3-4085-a5e8-dcf026ea0c4e',
                            'Content-Type': 'application/json'
                            &#125;,

                            body: JSON.stringify(&#123;"memberid":"2010040001"&#125;)

                            &#125;;

                            request(options, function (error, response) &#123;

                            if (error) throw new Error(error);
                            console.log(response.body);
                            &#125;);
                          </code>
                        </pre>
                      </div>
                    </div>
                  </div>

                </div>

              </div>

              <div class="p-lg-3">
                <h4 class="mt-0 mb-2 font-weight-bold">Register Member</h4>
                <h6>Endpoint: <code>/v1.0/member/register</code></h6>
                <h6>HTTP Method: <code>POST</code></h6>
                <h6>HTTP Header: <code>Authentication-Type</code></h6>
                <h6>Request Body: <code>
                  &#123;"firstname": "Francis", "lastname": "Owusu",
                  "middlename":
                  "Kwesi", "birthdate": "opd", "gender":"Male", "maritalstatus": "Single"&#125;
                </code></h6>
                <h6>Response Body: <code>&#123;status, message, claimid&#125;</code></h6>
                <code>&#123;"status": "200", "message": "Transaction Successful"&#125;</code> <br>

                <h6>Response Codes: </h6>
                <code>&#123;200: "Transaction Successful"&#125;</code> <br>
                <code>&#123;500: "Internal Server Error"&#125;</code> <br>
                <code>&#123;400: "Bad Request, Check Request API Key"&#125;</code> <br>
                <code>&#123;405: "Method not allowed"&#125;</code>
              </div>

              <div class="p-lg-3">
                <h4 class="mt-0 mb-2 font-weight-bold">Forward Claim</h4>
                <h6>Endpoint: <code>/v1.0/claim/forward</code></h6>
                <h6>HTTP Method: <code>POST</code></h6>
                <h6>HTTP Header: <code>Authentication-Type</code></h6>
                <h6>Request Body: <code>
                  &#123;"visitdate": "30-04-2020", "comment": "client was served and discharged successfully",
                  "memberid":
                  "2010040001", "servicetype": "opd", "dischargeddate":"30-04-2020",
                  "diagnoses": [&#123;"diagnosis": "PLASMODIUM VIVAX MALARIA"&#125;,&#123;"diagnosis":"WHOOPING COUGH
                  DUE TO BORDETELLA
                  PARAPERTUSSIS"&#125;],
                  "medications": [&#123;"medication": "ZINCOVITE TAB", "quantity": 2, "charge": 25.08, "dosage":
                  "daily"&#125;,
                  &#123;"medication": "", "quantity": 4, "charge": 154.55, "dosage":"daily"&#125;],
                  "investigations": [&#123;"investigation":"ALBUMIN", "charge": 23&#125;, &#123;"investigation": "EYE
                  SWAB C/S",
                  "charge": 33&#125;],
                  "consultations": [&#123;"description": "GP Consultation", "charge": 124.00&#125;],
                  "procedures": [&#123;"procedure": "CONSUMABLES", "quantity": 4, "charge": 28.50&#125;]&#125;


                </code></h6>
                <h6>Response Body: <code>&#123;status, message, claimid&#125;</code></h6>
                <code>&#123;"status": "200", "message": "Transaction Successful", "claimid":
                  "PPH20200502201010002"&#125;</code> <br>

                <h6>Response Codes: </h6>
                <code>&#123;200: "Transaction Successful"&#125;</code> <br>
                <code>&#123;500: "Internal Server Error"&#125;</code> <br>
                <code>&#123;400: "Bad Request, Check Request API Key"&#125;</code> <br>
                <code>&#123;405: "Method not allowed"&#125;</code>

                <h4>Examples </h4>
                <div class="accordion" id="accordionForward">

                  <div class="card">
                    <div class="card-header" id="headingOne">
                      <h2 class="mb-0">
                        <button class="btn btn-link btn-block text-left" style="padding: 0;" type="button"
                          data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                          aria-controls="collapseOne">
                          cURL
                        </button>
                      </h2>
                    </div>

                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                      data-parent="#accordionForward">
                      <div class="card-body" style="color: #17ff0b; background-color: #444;">
                        <pre style="color: #17ff0b; background-color: #444;"><code>
                          curl --location --request POST '/v1.0/claim/forward' \
                          --header 'Authorization-Type: 8acc1bc8-1dc3-4085-a5e8-dcf026ea0c4e' \
                          --header 'Content-Type: application/json' \
                          --data-raw '&#123;"visitdate": "30-04-2020", "comment": "client was served and discharged successfully",
                          "memberid": "2010040001", "servicetype": "opd", "dischargeddate":"30-04-2020",
                          "diagnoses": [&#123;"diagnosis": "PLASMODIUM VIVAX MALARIA"&#125;,&#123;"diagnosis":"WHOOPING COUGH DUE TO BORDETELLA
                          PARAPERTUSSIS"&#125;],
                          "medications": [&#123;"medication": "ZINCOVITE TAB", "quantity": 2, "charge": 25.08, "dosage": "daily"&#125;,
                          &#123;"medication": "", "quantity": 4, "charge": 154.55, "dosage":"daily"&#125;],
                          "investigations": [&#123;"investigation":"ALBUMIN", "charge": 23&#125;, &#123;"investigation": "EYE SWAB C/S",
                          "charge": 33&#125;],
                          "consultations": [&#123;"description": "GP Consultation", "charge": 124.00&#125;],
                          "procedures": [&#123;"procedure": "CONSUMABLES", "quantity": 4, "charge": 28.50&#125;]&#125;'
                        </code></pre>
                      </div>
                    </div>
                  </div>

                  <div class="card">
                    <div class="card-header" id="headingTwo">
                      <h2 class="mb-0">
                        <button class="btn btn-link btn-block text-left collapsed" style="padding: 0;" type="button"
                          data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
                          aria-controls="collapseTwo">
                          PHP - cURL
                        </button>
                      </h2>
                    </div>
                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionForward">
                      <div class="card-body" style="color: #17ff0b; background-color: #444;">
                        <pre style="color: #17ff0b; background-color: #444;"><code>
                          <?php

                          $curl = curl_init();

                          curl_setopt_array($curl, array(
                          CURLOPT_URL => "/v1.0/claim/forward",
                          CURLOPT_RETURNTRANSFER => true,
                          CURLOPT_ENCODING => "",
                          CURLOPT_MAXREDIRS => 10,
                          CURLOPT_TIMEOUT => 0,
                          CURLOPT_FOLLOWLOCATION => true,
                          CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
                          CURLOPT_CUSTOMREQUEST => "POST",
                          CURLOPT_POSTFIELDS =>"&#123;\"visitdate\": \"30-04-2020\", \"comment\": \"client was served and discharged successfully\", \"memberid\": \"2010040001\", \"servicetype\": \"opd\", \"dischargeddate\":\"30-04-2020\", \n\"diagnoses\": [&#123;\"diagnosis\": \"PLASMODIUM VIVAX MALARIA\"&#125;,&#123;\"diagnosis\":\"WHOOPING COUGH DUE TO BORDETELLA PARAPERTUSSIS\"&#125;], \n\"medications\": [&#123;\"medication\": \"ZINCOVITE TAB\", \"quantity\": 2, \"charge\": 25.08, \"dosage\": \"daily\"&#125;, &#123;\"medication\": \"\", \"quantity\": 4, \"charge\": 154.55, \"dosage\":\"daily\"&#125;], \n\"investigations\": [&#123;\"investigation\":\"ALBUMIN\", \"charge\": 23&#125;, &#123;\"investigation\": \"EYE SWAB C/S\", \"charge\": 33&#125;],\n\"procedures\": [&#123;\"procedure\": \"CONSUMABLES\", \"quantity\": 4, \"charge\": 28.50&#125;]&#125;",
                          CURLOPT_HTTPHEADER => array(
                          "Authorization-Type: 8acc1bc8-1dc3-4085-a5e8-dcf026ea0c4e",
                          "Content-Type: application/json"
                          ),
                          ));

                          $response = curl_exec($curl);

                          curl_close($curl);
                          echo $response;
                        </code></pre>
                      </div>
                    </div>
                  </div>

                  <div class="card">
                    <div class="card-header" id="headingThree">
                      <h2 class="mb-0">
                        <button style="padding: 0;" class="btn btn-link btn-block text-left collapsed" type="button"
                          data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                          aria-controls="collapseThree">
                          PHP - HTTP_Request2
                        </button>
                      </h2>
                    </div>
                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                      data-parent="#accordionForward">
                      <div class="card-body" style="color: #17ff0b; background-color: #444;">
                        <pre style="color: #17ff0b; background-color: #444;">
                          <code>
                            <?php
                            require_once 'HTTP/Request2.php';
                            $request = new HTTP_Request2();
                            $request->setUrl('/v1.0/claim/forward');
                            $request->setMethod(HTTP_Request2::METHOD_POST);
                            $request->setConfig(array(
                            'follow_redirects' => TRUE
                            ));
                            $request->setHeader(array(
                            'Authorization-Type' => '8acc1bc8-1dc3-4085-a5e8-dcf026ea0c4e',
                            'Content-Type' => 'application/json'
                            ));
                            $request->setBody('&#123;"visitdate": "30-04-2020", "comment": "client was served and discharged successfully", "memberid": "2010040001", "servicetype": "opd", "dischargeddate":"30-04-2020", \n"diagnoses": [&#123;"diagnosis": "PLASMODIUM VIVAX MALARIA"&#125;,&#123;"diagnosis":"WHOOPING COUGH DUE TO BORDETELLA PARAPERTUSSIS"&#125;], \n"medications": [&#123;"medication": "ZINCOVITE TAB", "quantity": 2, "charge": 25.08, "dosage": "daily"&#125;, &#123;"medication": "", "quantity": 4, "charge": 154.55, "dosage":"daily"&#125;], \n"investigations": [&#123;"investigation":"ALBUMIN", "charge": 23&#125;, &#123;"investigation": "EYE SWAB C/S", "charge": 33&#125;],\n"procedures": [&#123;"procedure": "CONSUMABLES", "quantity": 4, "charge": 28.50&#125;]&#125;');
                            try &#123;
                            $response = $request->send();
                            if ($response->getStatus() == 200) &#123;
                            echo $response->getBody();
                            &#125;
                            else &#123;
                            echo 'Unexpected HTTP status: ' . $response->getStatus() . ' ' .
                            $response->getReasonPhrase();
                            &#125;
                            &#125;
                            catch(HTTP_Request2_Exception $e) &#123;
                            echo 'Error: ' . $e->getMessage();
                            &#125;
                          </code>
                        </pre>
                      </div>
                    </div>
                  </div>

                  <div class="card">
                    <div class="card-header" id="headingFour">
                      <h2 class="mb-0">
                        <button style="padding: 0;" class="btn btn-link btn-block text-left collapsed" type="button"
                          data-toggle="collapse" data-target="#collapseFour" aria-expanded="false"
                          aria-controls="collapseFour">
                          Nodejs - Request
                        </button>
                      </h2>
                    </div>
                    <div id="collapseFour" class="collapse" aria-labelledby="headingFour"
                      data-parent="#accordionForward">
                      <div class="card-body" style="color: #17ff0b; background-color: #444;">
                        <pre style="color: #17ff0b; background-color: #444;">
                          <code>
                            var request = require('request');
                            var options = &#123;
                            'method': 'POST',
                            'url': '/v1.0/claim/forward',
                            'headers': &#123;
                            'Authorization-Type': '8acc1bc8-1dc3-4085-a5e8-dcf026ea0c4e',
                            'Content-Type': 'application/json'
                            &#125;,
                            body: JSON.stringify(&#123;"visitdate":"30-04-2020","comment":"client was served and discharged
                            successfully","memberid":"2010040001","servicetype":"opd","dischargeddate":"30-04-2020","diagnoses":[&#123;"diagnosis":"PLASMODIUM
                            VIVAX MALARIA"&#125;,&#123;"diagnosis":"WHOOPING COUGH DUE TO BORDETELLA
                            PARAPERTUSSIS"&#125;],"medications":[&#123;"medication":"ZINCOVITE
                            TAB","quantity":2,"charge":25.08,"dosage":"daily"&#125;,&#123;"medication":"","quantity":4,"charge":154.55,"dosage":"daily"&#125;],"investigations":[&#123;"investigation":"ALBUMIN","charge":23&#125;,&#123;"investigation":"EYE
                            SWAB C/S","charge":33&#125;],"procedures":[&#123;"procedure":"CONSUMABLES","quantity":4,"charge":28.5&#125;]&#125;)

                            &#125;;
                            request(options, function (error, response) &#123;
                            if (error) throw new Error(error);
                            console.log(response.body);
                            &#125;);

                          </code>
                        </pre>
                      </div>
                    </div>
                  </div>

                </div>

              </div>

              <div class="p-lg-3">
                <h4 class="mt-0 mb-2 font-weight-bold">Forward Bulk Claims</h4>
                <h6>Endpoint: <code>/v1.0/claim/forward/all</code></h6>
                <h6>HTTP Method: <code>POST</code></h6>
                <h6>HTTP Header: <code>Authentication-Type</code></h6>
                <h6>Request Body: <code>
                  [&#123;"visitdate": "30-04-2020", "comment": "client was served and discharged successfully",
                  "memberid":
                  "2010040001", "servicetype": "opd", "dischargeddate":"30-04-2020",
                  "diagnoses": [&#123;"diagnosis": "PLASMODIUM VIVAX MALARIA"&#125;,&#123;"diagnosis":"WHOOPING COUGH
                  DUE TO BORDETELLA
                  PARAPERTUSSIS"&#125;],
                  "medications": [&#123;"medication": "ZINCOVITE TAB", "quantity": 2, "charge": 25.08, "dosage":
                  "daily"&#125;,
                  &#123;"medication": "", "quantity": 4, "charge": 154.55, "dosage":"daily"&#125;],
                  "investigations": [&#123;"investigation":"ALBUMIN", "charge": 23&#125;, &#123;"investigation": "EYE
                  SWAB C/S",
                  "charge": 33&#125;],
                  "consultations": [&#123;"description": "GP Consultation", "charge": 124.00&#125;]
                  "procedures": [&#123;"procedure": "CONSUMABLES", "quantity": 4, "charge": 28.50&#125;]&#125;]

                </code></h6>
                <h6>Response Body: <code>&#123;status, message&#125;</code></h6>
                <code>&#123;"status": "200", "message": "Transaction Successful"&#125;</code> <br>

                <h6>Response Codes: </h6>
                <code>&#123;200: "Transaction Successful"&#125;</code> <br>
                <code>&#123;500: "Internal Server Error"&#125;</code> <br>
                <code>&#123;400: "Bad Request, Check Request API Key"&#125;</code> <br>
                <code>&#123;405: "Method not allowed"&#125;</code>

                <h4>Examples </h4>
                <div class="accordion" id="accordionForwardAll">

                  <div class="card">
                    <div class="card-header" id="headingOneAll">
                      <h2 class="mb-0">
                        <button class="btn btn-link btn-block text-left" style="padding: 0;" type="button"
                          data-toggle="collapse" data-target="#collapseOneAll" aria-expanded="true"
                          aria-controls="collapseOneAll">
                          cURL
                        </button>
                      </h2>
                    </div>

                    <div id="collapseOneAll" class="collapse show" aria-labelledby="headingOneAll"
                      data-parent="#accordionForwardAll">
                      <div class="card-body" style="color: #17ff0b; background-color: #444;">
                        <pre style="color: #17ff0b; background-color: #444;"><code>
                          curl --location --request POST '/v1.0/claim/forward' \
                          --header 'Authorization-Type: 8acc1bc8-1dc3-4085-a5e8-dcf026ea0c4e' \
                          --header 'Content-Type: application/json' \
                          --data-raw '[&#123;"visitdate": "30-04-2020", "comment": "client was served and discharged successfully",
                          "memberid": "2010040001", "servicetype": "opd", "dischargeddate":"30-04-2020",
                          "diagnoses": [&#123;"diagnosis": "PLASMODIUM VIVAX MALARIA"&#125;,&#123;"diagnosis":"WHOOPING COUGH DUE TO BORDETELLA
                          PARAPERTUSSIS"&#125;],
                          "medications": [&#123;"medication": "ZINCOVITE TAB", "quantity": 2, "charge": 25.08, "dosage": "daily"&#125;,
                          &#123;"medication": "", "quantity": 4, "charge": 154.55, "dosage":"daily"&#125;],
                          "investigations": [&#123;"investigation":"ALBUMIN", "charge": 23&#125;, &#123;"investigation": "EYE SWAB C/S",
                          "charge": 33&#125;],
                          "procedures": [&#123;"procedure": "CONSUMABLES", "quantity": 4, "charge": 28.50&#125;]&#125;]'
                        </code></pre>
                      </div>
                    </div>
                  </div>

                  <div class="card">
                    <div class="card-header" id="headingTwoAll">
                      <h2 class="mb-0">
                        <button class="btn btn-link btn-block text-left collapsed" style="padding: 0;" type="button"
                          data-toggle="collapse" data-target="#collapseTwoAll" aria-expanded="false"
                          aria-controls="collapseTwoAll">
                          PHP - cURL
                        </button>
                      </h2>
                    </div>
                    <div id="collapseTwoAll" class="collapse" aria-labelledby="headingTwoAll"
                      data-parent="#accordionForwardAll">
                      <div class="card-body" style="color: #17ff0b; background-color: #444;">
                        <pre style="color: #17ff0b; background-color: #444;"><code>
                          <?php

                          $curl = curl_init();

                          curl_setopt_array($curl, array(
                          CURLOPT_URL => "/v1.0/claim/forward",
                          CURLOPT_RETURNTRANSFER => true,
                          CURLOPT_ENCODING => "",
                          CURLOPT_MAXREDIRS => 10,
                          CURLOPT_TIMEOUT => 0,
                          CURLOPT_FOLLOWLOCATION => true,
CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
                          CURLOPT_CUSTOMREQUEST => "POST",
                          CURLOPT_POSTFIELDS =>"[&#123;\"visitdate\": \"30-04-2020\", \"comment\": \"client was served and discharged successfully\", \"memberid\": \"2010040001\", \"servicetype\": \"opd\", \"dischargeddate\":\"30-04-2020\", \n\"diagnoses\": [&#123;\"diagnosis\": \"PLASMODIUM VIVAX MALARIA\"&#125;,&#123;\"diagnosis\":\"WHOOPING COUGH DUE TO BORDETELLA PARAPERTUSSIS\"&#125;], \n\"medications\": [&#123;\"medication\": \"ZINCOVITE TAB\", \"quantity\": 2, \"charge\": 25.08, \"dosage\": \"daily\"&#125;, &#123;\"medication\": \"\", \"quantity\": 4, \"charge\": 154.55, \"dosage\":\"daily\"&#125;], \n\"investigations\": [&#123;\"investigation\":\"ALBUMIN\", \"charge\": 23&#125;, &#123;\"investigation\": \"EYE SWAB C/S\", \"charge\": 33&#125;],\n\"procedures\": [&#123;\"procedure\": \"CONSUMABLES\", \"quantity\": 4, \"charge\": 28.50&#125;]&#125;]",
                          CURLOPT_HTTPHEADER => array(
                          "Authorization-Type: 8acc1bc8-1dc3-4085-a5e8-dcf026ea0c4e",
                          "Content-Type: application/json"
                          ),
                          ));

                          $response = curl_exec($curl);

                          curl_close($curl);
                          echo $response;
                        </code></pre>
                      </div>
                    </div>
                  </div>

                  <div class="card">
                    <div class="card-header" id="headingThreeAll">
                      <h2 class="mb-0">
                        <button style="padding: 0;" class="btn btn-link btn-block text-left collapsed" type="button"
                          data-toggle="collapse" data-target="#collapseThreeAll" aria-expanded="false"
                          aria-controls="collapseThreeAll">
                          PHP - HTTP_Request2
                        </button>
                      </h2>
                    </div>
                    <div id="collapseThreeAll" class="collapse" aria-labelledby="headingThreeAll"
                      data-parent="#accordionForwardAll">
                      <div class="card-body" style="color: #17ff0b; background-color: #444;">
                        <pre style="color: #17ff0b; background-color: #444;">
                          <code>
                            <?php
                            require_once 'HTTP/Request2.php';
                            $request = new HTTP_Request2();
                            $request->setUrl('/v1.0/claim/forward');
                            $request->setMethod(HTTP_Request2::METHOD_POST);
                            $request->setConfig(array(
                            'follow_redirects' => TRUE
                            ));
                            $request->setHeader(array(
                            'Authorization-Type' => '8acc1bc8-1dc3-4085-a5e8-dcf026ea0c4e',
                            'Content-Type' => 'application/json'
                            ));
                            $request->setBody('[&#123;"visitdate": "30-04-2020", "comment": "client was served and discharged successfully", "memberid": "2010040001", "servicetype": "opd", "dischargeddate":"30-04-2020", \n"diagnoses": [&#123;"diagnosis": "PLASMODIUM VIVAX MALARIA"&#125;,&#123;"diagnosis":"WHOOPING COUGH DUE TO BORDETELLA PARAPERTUSSIS"&#125;], \n"medications": [&#123;"medication": "ZINCOVITE TAB", "quantity": 2, "charge": 25.08, "dosage": "daily"&#125;, &#123;"medication": "", "quantity": 4, "charge": 154.55, "dosage":"daily"&#125;], \n"investigations": [&#123;"investigation":"ALBUMIN", "charge": 23&#125;, &#123;"investigation": "EYE SWAB C/S", "charge": 33&#125;],\n"procedures": [&#123;"procedure": "CONSUMABLES", "quantity": 4, "charge": 28.50&#125;]&#125;]');
                            try &#123;
                            $response = $request->send();
                            if ($response->getStatus() == 200) &#123;
                            echo $response->getBody();
                            &#125;
                            else &#123;
                            echo 'Unexpected HTTP status: ' . $response->getStatus() . ' ' .
                            $response->getReasonPhrase();
                            &#125;
                            &#125;
                            catch(HTTP_Request2_Exception $e) &#123;
                            echo 'Error: ' . $e->getMessage();
                            &#125;
                          </code>
                        </pre>
                      </div>
                    </div>
                  </div>

                  <div class="card">
                    <div class="card-header" id="headingFourAll">
                      <h2 class="mb-0">
                        <button style="padding: 0;" class="btn btn-link btn-block text-left collapsed" type="button"
                          data-toggle="collapse" data-target="#collapseFourAll" aria-expanded="false"
                          aria-controls="collapseFourAll">
                          Nodejs - Request
                        </button>
                      </h2>
                    </div>
                    <div id="collapseFourAll" class="collapse" aria-labelledby="headingFourAll"
                      data-parent="#accordionForwardAll">
                      <div class="card-body" style="color: #17ff0b; background-color: #444;">
                        <pre style="color: #17ff0b; background-color: #444;">
                          <code>
                            var request = require('request');
                            var options = &#123;
                            'method': 'POST',
                            'url': '/v1.0/claim/forward',
                            'headers': &#123;
                            'Authorization-Type': '8acc1bc8-1dc3-4085-a5e8-dcf026ea0c4e',
                            'Content-Type': 'application/json'
                            &#125;,
body: JSON.stringify([&#123;"visitdate":"30-04-2020","comment":"client was served and discharged
                            successfully","memberid":"2010040001","servicetype":"opd","dischargeddate":"30-04-2020","diagnoses":[&#123;"diagnosis":"PLASMODIUM
                            VIVAX MALARIA"&#125;,&#123;"diagnosis":"WHOOPING COUGH DUE TO BORDETELLA
                            PARAPERTUSSIS"&#125;],"medications":[&#123;"medication":"ZINCOVITE
                            TAB","quantity":2,"charge":25.08,"dosage":"daily"&#125;,&#123;"medication":"","quantity":4,"charge":154.55,"dosage":"daily"&#125;],"investigations":[&#123;"investigation":"ALBUMIN","charge":23&#125;,&#123;"investigation":"EYE
                            SWAB C/S","charge":33&#125;],"procedures":[&#123;"procedure":"CONSUMABLES","quantity":4,"charge":28.5&#125;]&#125;])

                            &#125;;
                            request(options, function (error, response) &#123;
                            if (error) throw new Error(error);
                            console.log(response.body);
                            &#125;);

                          </code>
                        </pre>
                      </div>
                    </div>
                  </div>

                </div>

              </div>

              <div class="p-lg-3">
                <h4 class="mt-0 mb-2 font-weight-bold">Claims History</h4>
                <h6>Endpoint: <code>/v1.0/claim/history</code></h6>
                <h6>HTTP Method: <code>GET</code></h6>
                <h6>HTTP Header: <code>Authentication-Type</code></h6>
                <h6>Request Body: <code>[memberid]</code></h6>
                <h6>Response Body: <code>[status, message, client]</code></h6>
                <code>["status": "200", "message": "Transaction Successful"]</code> <br>

                <h6>Response Codes: </h6>
                <code>[200: "Transaction Successful"]</code> <br>
                <code>[500: "Internal Server Error"]</code> <br>
                <code>[400: "Bad Request, Check Request API Key"]</code> <br>
                <code>[405: "Method not allowed" ]</code>

              </div>


            </div>
          </div>

        </div>

      </div>

      <div class="row">
        <div class="col-md-2">

        </div>

        <div class="col-md-10">

        </div>
      </div>
    </div>

  </div>


</div>
